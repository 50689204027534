import { Box, Grid, Typography } from '@mui/material'
import { Slide } from 'react-awesome-reveal'
import {
  StatisticItem,
  StatisticSectionProps,
} from '../../../types/homepage/HomePageTypes'

const statistics: StatisticItem[] = [
  {
    value: '1M+',
    label: 'Daily Impact',
    description:
      'We influence millions of users and businesses daily with our services.',
  },
  {
    value: '99%',
    label: 'Client Satisfaction',
    description:
      'Delivering Exceptional Results with a 99% Client Satisfaction Rate',
  },
  {
    value: '20+',
    label: 'Industries Served',
    description: 'Experience Across a Diverse Range of 20+ Industries',
  },
  {
    value: '600+',
    label: 'Successful Projects',
    description:
      'Whatever the challenge, we deliver results that exceed expectations',
  },
]

const StatisticSection = (props: StatisticSectionProps) => {
  const stats = props.statistics ? props.statistics : statistics

  return (
    <Box
      sx={{
        py: 6,
        textAlign: 'center',
        backgroundColor: props.backgroundColor === 'Black' ? '#000' : '#fff',
        minHeight: '600px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        px: { xs: 2, sm: 4, md: 6 },
      }}
    >
      {/* Title */}
      <Typography
        variant='h2'
        fontSize={{ xs: '2rem', sm: '2.5rem', md: '3rem' }}
        fontWeight='bold'
        sx={{ color: props.backgroundColor === 'Black' ? '#fff' : '#000' }}
        gutterBottom
        mt={2}
      >
        From Concept to Launch we help you with everything.
      </Typography>

      {/* Subtitle */}
      <Typography
        variant='h6'
        sx={{
          color: props.backgroundColor === 'Black' ? '#fff' : '#000',
          maxWidth: '1200px',
          px: { xs: 2, sm: 4 },
        }}
        mt={3}
        mb={4}
      >
        Unleash the power of AI to streamline your workflows, automate tasks,
        and focus on what truly matters. Our solutions seamlessly integrate into
        your existing processes, eliminating time-consuming tasks and boosting
        productivity to new heights.
      </Typography>

      {/* Statistics */}
      <Grid container spacing={{ xs: 2, sm: 3 }} justifyContent='center' mt={3}>
        {stats.map(({ value, label, description }) => (
          <Grid item xs={12} sm={6} md={3} key={label}>
            <Slide direction='up'>
              <Box mt={2} textAlign='center'>
                <Typography
                  variant='h2'
                  sx={{ color: '#00FF00', letterSpacing: 2.6 }}
                  fontWeight='bold'
                  fontSize={{ xs: '1.5rem', sm: '2rem', md: '2.5rem' }}
                >
                  {value}
                </Typography>
                <Typography
                  variant='h6'
                  fontSize={{ xs: '1.2rem', sm: '1.5rem', md: '2.2rem' }}
                  sx={{
                    color: props.backgroundColor === 'Black' ? '#fff' : '#000',
                  }}
                  fontWeight='bold'
                >
                  {label}
                </Typography>
                <Typography
                  variant='body1'
                  sx={{
                    color:
                      props.backgroundColor === 'Black' ? 'lightgray' : '#000',
                    my: 2,
                    px: 2,
                  }}
                  maxWidth='300px'
                  mx='auto'
                  fontSize={{ xs: '0.9rem', sm: '1rem', md: '1.1rem' }}
                >
                  {description}
                </Typography>
              </Box>
            </Slide>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default StatisticSection
