import { Typography, Box, Link } from '@mui/material'
import { styled } from '@mui/system'
import GeniusImage from '../../atoms/GeniusImage'
import { EmailOutlined, HouseOutlined } from '@mui/icons-material'
import NotFoundTemplateHelmet from './NotFoundTemplateHelmet'

const Custom404Box = styled(Box)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  padding: theme.spacing(5),
  backgroundColor: theme.palette.common.white,
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 500,
  color: theme.palette.common.black,
}))

const NotFoundTemplate = () => (
  <>
    <NotFoundTemplateHelmet />
    <Custom404Box>
      <GeniusImage src={'/images/404.svg'} alt='404' width={500} />
      <StyledTypography variant='h1' mt={5}>
        We can't find this page
      </StyledTypography>
      <StyledTypography variant='subtitle1'>
        The page you are looking for doesn’t exist or has been moved.
      </StyledTypography>
      f
      <Link href='/' underline='none'>
        <Box
          display={'flex'}
          color={'common.black'}
          textAlign={'left'}
          my={2}
          pt={2}
          borderBottom={'2px solid rgba(0,0,0,0.1)'}
          borderTop={'2px solid rgba(0,0,0,0.1)'}
        >
          <Box m={'auto'} p={2}>
            <HouseOutlined sx={{ marginRight: '1rem', fontSize: '2.5rem' }} />
          </Box>
          <Box>
            <Typography variant={'h5'} fontWeight={'bold'} letterSpacing={2}>
              Home
            </Typography>
            <Typography variant={'h6'}>
              Can't find what you're looking for?
            </Typography>
          </Box>
        </Box>
      </Link>
      <Link href='mailto:support@geniusaisolutions.com' underline='none'>
        <Box
          display={'flex'}
          color={'common.black'}
          textAlign={'left'}
          my={2}
          pb={2}
          borderBottom={'2px solid rgba(0,0,0,0.1)'}
        >
          <Box m={'auto'} p={2}>
            <EmailOutlined sx={{ marginRight: '1rem', fontSize: '2.1rem' }} />
          </Box>
          <Box>
            <Typography variant={'h5'} fontWeight={'bold'} letterSpacing={2}>
              Mail Us
            </Typography>
            <Typography variant={'h6'}>
              Can't find what you're looking for?
            </Typography>
          </Box>
        </Box>
      </Link>
    </Custom404Box>
  </>
)

export default NotFoundTemplate
