import {
  Box,
  Typography,
  ListItem,
  ListItemText,
  Divider,
  Link,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import _ from 'lodash'
import { ourServices } from '../../../config/constants'
import {
  StyledSection,
  LeftSection,
  ServicesList,
  BrandSection,
  GreenButton,
  OutlineButton,
  LogoContainer,
} from './HeroSection.styles'
import GeniusImage from '../../atoms/GeniusImage'
import AccentTitle from '../../atoms/AccentTitle'
import { Fade, Slide } from 'react-awesome-reveal'

const HeroSection = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <StyledSection>
      <LeftSection>
        <Typography variant={isMobile ? 'h3' : 'h1'} fontWeight='bold'>
          <Slide direction='left'>
            <AccentTitle>GENIUS AI SOLUTIONS</AccentTitle>
          </Slide>
          Unleash the power of Genius AI.
        </Typography>
        <Typography variant='h6' textAlign={isMobile ? 'center' : 'left'}>
          Empowering businesses to optimize processes, automate tasks, and
          maximize ROI through cutting-edge AI and software solutions.
        </Typography>
        <Fade delay={100}>
          <Box
            display='flex'
            gap={2}
            justifyContent={isMobile ? 'center' : 'flex-start'}
          >
            <GreenButton
              variant='contained'
              href='mailto:contact@geniusaisolutions.com'
            >
              CONTACT US
            </GreenButton>
            <OutlineButton variant='outlined' href='/pricing'>
              VIEW PRICING
            </OutlineButton>
          </Box>
        </Fade>

        <Slide direction='left'>
          <BrandSection>
            <LogoContainer>
              <GeniusImage
                src='/images/common/brands/jamm.png'
                alt='JAMM VERSE'
                width={isMobile ? 75 : 150}
                style={{
                  filter: 'grayscale(50%) brightness(0) invert(1)',
                  opacity: '0.3',
                }}
              />
            </LogoContainer>
            <LogoContainer>
              <GeniusImage
                src='/images/common/brands/8fig.png'
                alt='8 FIG LOGO'
                width={isMobile ? 50 : 80}
                style={{
                  filter: 'grayscale(100%) brightness(0) invert(1)',
                  opacity: '0.3',
                }}
              />
            </LogoContainer>
            <LogoContainer>
              <GeniusImage
                src='/images/common/brands/tutortech-text.png'
                alt='Tutor Tech Logo'
                width={isMobile ? 120 : 200}
                style={{
                  filter: 'grayscale(100%) brightness(0) invert(1)',
                  opacity: '0.3',
                }}
              />
            </LogoContainer>
          </BrandSection>
        </Slide>
      </LeftSection>
      <Fade delay={100}>
        <ServicesList>
          <Typography
            variant='h6'
            fontWeight='bold'
            gutterBottom
            letterSpacing={1.5}
          >
            Our Services
          </Typography>
          <Divider />
          {_.map(ourServices, (service, index) => (
            <ListItem key={index} component='div' disableGutters>
              {service.icon && (
                <service.icon style={{ marginRight: 10, fontSize: '2rem' }} />
              )}
              <Link
                href={service.link}
                underline='none'
                color='#fff'
                onMouseOver={(e) => {
                  e.currentTarget.style.color = '#00FF00'
                  e.currentTarget.style.fontWeight = 'bold'
                  if (e.currentTarget.parentElement) {
                    ;(
                      e.currentTarget.parentElement as HTMLElement
                    ).style.color = '#00FF00'
                  }
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.color = '#fff'
                  if (e.currentTarget.parentElement) {
                    ;(
                      e.currentTarget.parentElement as HTMLElement
                    ).style.color = 'inherit'
                  }
                }}
              >
                <ListItemText
                  primary={
                    <Box display='flex' alignItems='center'>
                      <Typography variant='h6'> {service.name} </Typography>
                    </Box>
                  }
                  secondary={
                    <Typography variant='body2'>
                      {' '}
                      {service.description}{' '}
                    </Typography>
                  }
                />
              </Link>
            </ListItem>
          ))}
        </ServicesList>
      </Fade>
    </StyledSection>
  )
}

export default HeroSection
