interface GeniusConfiguration {
  navigation: {
    isToolsPageDisplayed: boolean
    isServicesPageDisplayed: boolean
    isProductsPageDisplayed: boolean
    isPricingPageDisplayed: boolean
    isMoreSubNavDisplayed: boolean
    isCartIconEnabled: boolean
    isAccountIconEnabled: boolean
    isSearchIconEnabled: boolean
  }
  routes: {
    isHomePageEnabled: boolean
    isFeaturesPageEnabled: boolean
    isPricingPageEnabled: boolean
    isSpecialitiesPageEnabled: boolean
    isContactPageEnabled: boolean
    isAboutUsPageEnabled: boolean
    isCareersPageEnabled: boolean
    isPressPageEnabled: boolean
    isPrivacyPolicyPageEnabled: boolean
    isTermsOfServicePageEnabled: boolean
    isBlogPageEnabled: boolean
    isBlogPostPageEnabled: boolean
    isDevelopersPageEnabled: boolean
    isForumsPageEnabled: boolean
    isForumTopicPageEnabled: boolean
    isLoginPageEnabled: boolean
    isHelpPageEnabled: boolean
    isSignUpPageEnabled: boolean
    isAccountPageEnabled: boolean
    isAccountSettingsPageEnabled: boolean
    isSearchPageEnabled: boolean
    isNewsletterSignUpPageEnabled: boolean
    isComingSoonPageEnabled: boolean
    isMaintenancePageEnabled: boolean
    isPasswordResetPageEnabled: boolean
    isDashboardPageEnabled: boolean
    isNotFoundPageEnabled: boolean
    services: {
      isSoftwareDevelopmentPageEnabled: boolean
      isProcessAutomationPageEnabled: boolean
      isWebsiteDevelopmentPageEnabled: boolean
      isAIMLPageEnabled: boolean
      isConsultingPageEnabled: boolean
      isMobileApplicationPageEnabled: boolean
    }
  }
  seo: {
    metaData: {
      robots: { name: string; content: string }
      viewport: { name: string; content: string }
      contentType: { 'http-equiv': string; content: string }
      xUACompatible: { 'http-equiv': string; content: string }
      ogType: { property: string; content: string }
      ogLocale: { property: string; content: string }
      twitterCard: { name: string; content: string }
      twitterSite: { name: string; content: string }
      twitterCreator: { name: string; content: string }
      themeColor: { name: string; content: string }
      msTileColor: { name: string; content: string }
      msConfig: { name: string; content: string }
    }
    linkTags: {
      manifest: { rel: string; href: string }
    }
    schema: {
      '@context': string
      '@type': string
      name: string
      url: string
      logo: string
      sameAs: string[]
      contactPoint: {
        '@type': string
        email: string
        contactType: string
        areaServed: string
        availableLanguage: string[]
      }
      hasPart: {
        '@type': string
        name: string
        url: string
        address: {
          '@type': string
          streetAddress: string
          addressLocality: string
          addressRegion: string
          postalCode: string
          addressCountry: string
        }
        telephone: string
        openingHours: string
        geo: {
          '@type': string
          latitude: string
          longitude: string
        }
      }
    }
  }
}

const GeniusConfiguration: GeniusConfiguration = {
  navigation: {
    isToolsPageDisplayed: false,
    isServicesPageDisplayed: true,
    isProductsPageDisplayed: false,
    isPricingPageDisplayed: true,
    isMoreSubNavDisplayed: false,
    isCartIconEnabled: false,
    isAccountIconEnabled: false,
    isSearchIconEnabled: false,
  },
  routes: {
    isHomePageEnabled: true,
    isFeaturesPageEnabled: true,
    isPricingPageEnabled: true,
    isSpecialitiesPageEnabled: true,
    isContactPageEnabled: false,
    isAboutUsPageEnabled: true,
    isCareersPageEnabled: true,
    isPressPageEnabled: false,
    isPrivacyPolicyPageEnabled: true,
    isTermsOfServicePageEnabled: true,
    isBlogPageEnabled: false,
    isBlogPostPageEnabled: false,
    isDevelopersPageEnabled: false,
    isForumsPageEnabled: false,
    isForumTopicPageEnabled: false,
    isLoginPageEnabled: true,
    isHelpPageEnabled: true,
    isSignUpPageEnabled: true,
    isAccountPageEnabled: true,
    isAccountSettingsPageEnabled: true,
    isSearchPageEnabled: true,
    isNewsletterSignUpPageEnabled: true,
    isComingSoonPageEnabled: true,
    isMaintenancePageEnabled: true,
    isPasswordResetPageEnabled: true,
    isDashboardPageEnabled: true,
    isNotFoundPageEnabled: true,
    services: {
      isSoftwareDevelopmentPageEnabled: true,
      isProcessAutomationPageEnabled: true,
      isWebsiteDevelopmentPageEnabled: true,
      isAIMLPageEnabled: true,
      isConsultingPageEnabled: true,
      isMobileApplicationPageEnabled: true,
    },
  },
  seo: {
    metaData: {
      // Basic Meta Tags
      robots: { name: 'robots', content: 'index, follow' },
      viewport: {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0',
      },
      contentType: {
        'http-equiv': 'Content-Type',
        content: 'text/html; charset=UTF-8',
      },
      xUACompatible: { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
      // Open Graph / Facebook Meta Tags
      ogType: { property: 'og:type', content: 'website' },
      ogLocale: { property: 'og:locale', content: 'en_US' },
      // Twitter Meta Tags
      twitterCard: { name: 'twitter:card', content: 'summary_large_image' },
      twitterSite: { name: 'twitter:site', content: '@Genius_AI_Sol' },
      twitterCreator: { name: 'twitter:creator', content: '@Genius_AI_Sol' },
      // Additional SEO Enhancements
      themeColor: { name: 'theme-color', content: '#ffffff' },
      msTileColor: { name: 'msapplication-TileColor', content: '#ffffff' },
      msConfig: {
        name: 'msapplication-config',
        content: 'https://geniusaisolutions.com/browserconfig.xml',
      },
    },
    linkTags: {
      manifest: {
        rel: 'manifest',
        href: 'https://geniusaisolutions.com/site.webmanifest',
      },
    },
    schema: {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'Genius AI Solutions',
      url: 'https://geniusaisolutions.com',
      logo: 'https://geniusaisolutions.com/logo.png',
      sameAs: [
        'https://www.facebook.com/people/Genius-AI-Solutions/61567866718089/',
        'https://www.x.com/Genius_AI_Sol',
        'https://www.instagram.com/geniusaisolutions',
        'https://www.linkedin.com/company/geniusaisolutions/',
        'https://www.youtube.com/channel/UCDWOedlMln2fBdURbAzB_xQ',
      ],
      contactPoint: {
        '@type': 'ContactPoint',
        email: 'contact@geniusaisolutions.com',
        contactType: 'Customer Service',
        areaServed: 'Worldwide',
        availableLanguage: ['English'],
      },
      hasPart: {
        '@type': 'LocalBusiness',
        name: 'Genius AI',
        url: 'https://geniusaisolutions.com',
        address: {
          '@type': 'PostalAddress',
          streetAddress: '431B 41st Avenue NE',
          addressLocality: 'Calgary',
          addressRegion: 'AB',
          postalCode: 'T2E 2N4',
          addressCountry: 'Canada',
        },
        telephone: '+4034567890',
        openingHours: 'Mo-Su 00:00-23:59',
        geo: {
          '@type': 'GeoCoordinates',
          latitude: '51.089660',
          longitude: '-114.053470',
        },
      },
    },
  },
}

export default GeniusConfiguration
