import { Box, Button, List } from '@mui/material'
import { styled } from '@mui/system'

export const StyledSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  justifyContent: 'space-between',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  gap: theme.spacing(4),
  minHeight: '100vh',
}))

export const LeftSection = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: '60%',
  },
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))

export const ServicesList = styled(List)(({ theme }) => ({
  backgroundColor: '#111',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  color: theme.palette.common.white,
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '500px',
  },
}))

export const BrandSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-evenly',
  },
  alignItems: 'center',
  marginTop: theme.spacing(15),
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  backgroundColor: '#111',
  borderRadius: '30px',
  maxWidth: '100%',
}))

export const GreenButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.accent.main,
  color: theme.palette.common.black,
  padding: theme.spacing(1.5, 3),
  borderRadius: '30px',
  '&:hover': {
    backgroundColor: theme.palette.accent.secondary,
  },
}))

export const OutlineButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.common.white,
  color: theme.palette.common.white,
  padding: theme.spacing(1.5, 3),
  borderRadius: '30px',
  borderWidth: '2px',
  '&:hover': {
    borderColor: theme.palette.common.white,
    backgroundColor: '#333',
  },
}))

export const LogoContainer = styled(Box)(() => ({
  textAlign: 'center',
  minWidth: '80px',
}))
