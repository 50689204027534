import React, { useState } from 'react'
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  useTheme,
} from '@mui/material'
import CloudIcon from '@mui/icons-material/Cloud'
import SecurityIcon from '@mui/icons-material/Security'
import BackupIcon from '@mui/icons-material/Backup'
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic'
import EcoIcon from '@mui/icons-material/Code'
import CodeIcon from '@mui/icons-material/Code'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'
import {
  BuildCircleOutlined,
  Insights,
  SecurityRounded,
} from '@mui/icons-material'
import { Fade } from 'react-awesome-reveal'

const webHostingPlans = [
  {
    id: 'plan-1',
    price: '$15',
    features: [
      {
        id: 'feature-1-1',
        icon: <CloudIcon color='success' />,
        header: '99.9% uptime',
        text: 'Ensures your site is always available.',
      },
      {
        id: 'feature-1-2',
        icon: <BackupIcon color='success' />,
        header: 'Hourly backups',
        text: 'Maximum protection for critical data.',
      },
      {
        id: 'feature-1-3',
        icon: <HeadsetMicIcon color='success' />,
        header: 'Priority 24/7 support',
        text: 'Dedicated assistance whenever you need it.',
      },
      {
        id: 'feature-1-4',
        icon: <SecurityRounded color='success' />,
        header: 'SSL Certificate',
        text: 'Safeguarding user data and building trust.',
      },
      {
        id: 'feature-1-5',
        icon: <BuildCircleOutlined color='success' />,
        header: 'Easy-to-use control panel',
        text: 'Effortlessly manage your website settings.',
      },
      {
        id: 'feature-1-6',
        icon: <CloudIcon color='success' />,
        header: 'Free domain',
        text: 'Includes one free domain registration for a year.',
      },
      {
        id: 'feature-1-7',
        icon: <Insights color='success' />,
        header: 'Advanced analytics',
        text: 'Detailed insights to optimize your website.',
      },
    ],
  },
  {
    id: 'plan-2',
    price: '$50',
    features: [
      {
        id: 'feature-2-1',
        icon: <CloudIcon color='success' />,
        header: '99.9% uptime',
        text: 'Ensures your site is always available.',
      },
      {
        id: 'feature-2-2',
        icon: <BackupIcon color='success' />,
        header: 'Daily backups',
        text: 'Provides peace of mind with reliable recovery options.',
      },
      {
        id: 'feature-2-3',
        icon: <HeadsetMicIcon color='success' />,
        header: '24/7 support',
        text: 'Comprehensive assistance any time you need it.',
      },
      {
        id: 'feature-2-4',
        icon: <SecurityRounded color='success' />,
        header: 'SSL Certificate',
        text: 'Safeguarding user data and building trust.',
      },
      {
        id: 'feature-2-5',
        icon: <BuildCircleOutlined color='success' />,
        header: 'Easy-to-use control panel',
        text: 'Effortlessly manage your website settings.',
      },
      {
        id: 'feature-2-6',
        icon: <CloudIcon color='success' />,
        header: 'Free domain',
        text: 'One free domain registration for a year.',
      },
      {
        id: 'feature-2-7',
        icon: <Insights color='success' />,
        header: 'Advanced analytics',
        text: 'Detailed insights to optimize your website.',
      },
    ],
  },
  {
    id: 'plan-3',
    price: '$120',
    features: [
      {
        id: 'feature-3-1',
        icon: <CloudIcon color='success' />,
        header: '99.9% uptime',
        text: 'Ensures your site is always available.',
      },
      {
        id: 'feature-3-2',
        icon: <BackupIcon color='success' />,
        header: 'Hourly backups',
        text: 'Maximum protection for critical data.',
      },
      {
        id: 'feature-3-3',
        icon: <HeadsetMicIcon color='success' />,
        header: 'Priority 24/7 support',
        text: 'Dedicated assistance whenever you need it.',
      },
      {
        id: 'feature-3-4',
        icon: <SecurityRounded color='success' />,
        header: 'SSL Certificate',
        text: 'Safeguarding user data and building trust.',
      },
      {
        id: 'feature-3-5',
        icon: <BuildCircleOutlined color='success' />,
        header: 'Easy-to-use control panel',
        text: 'Effortlessly manage your website settings.',
      },
      {
        id: 'feature-3-6',
        icon: <CloudIcon color='success' />,
        header: 'Free domain',
        text: 'Includes one free domain registration for a year.',
      },
      {
        id: 'feature-3-7',
        icon: <Insights color='success' />,
        header: 'Advanced analytics',
        text: 'Detailed insights to optimize your website.',
      },
    ],
  },
]

const otherPlans = [
  {
    id: 'other-plan-1',
    title: 'Web Development',
    price: ['Pricing available on request'],
    features: [
      {
        id: 'other-feature-1-1',
        icon: <CodeIcon color='success' />,
        header: 'Responsive designs',
        text: 'Optimized for all screen sizes.',
      },
      {
        id: 'other-feature-1-2',
        icon: <IntegrationInstructionsIcon color='success' />,
        header: 'SEO-optimized',
        text: 'Boosts search engine rankings.',
      },
      {
        id: 'other-feature-1-3',
        icon: <CloudIcon color='success' />,
        header: 'CMS integration',
        text: 'Easy content updates and management.',
      },
      {
        id: 'other-feature-1-4',
        icon: <HeadsetMicIcon color='success' />,
        header: 'Interactive elements',
        text: 'Engages visitors with dynamic content.',
      },
      {
        id: 'other-feature-1-5',
        icon: <SecurityIcon color='success' />,
        header: 'Customizable templates',
        text: 'Tailored to your brand’s needs.',
      },
      {
        id: 'other-feature-1-6',
        icon: <CloudIcon color='success' />,
        header: 'Cross-browser compatibility',
        text: 'Uniform experience across all browsers.',
      },
      {
        id: 'other-feature-1-7',
        icon: <BackupIcon color='success' />,
        header: 'Fast-loading pages',
        text: 'Improved user experience and SEO.',
      },
      {
        id: 'other-feature-1-8',
        icon: <EcoIcon color='success' />,
        header: 'Regular maintenance and updates',
        text: 'Ensures a fresh and secure site.',
      },
    ],
    buttonText: 'Contact sales',
  },
  {
    id: 'other-plan-2',
    title: 'Software Development',
    price: ['Pricing available on request'],
    features: [
      {
        id: 'other-feature-2-1',
        icon: <CloudIcon color='success' />,
        header: 'Custom solutions',
        text: 'Built to meet your unique requirements.',
      },
      {
        id: 'other-feature-2-2',
        icon: <BackupIcon color='success' />,
        header: 'Agile development process',
        text: 'Iterative and client-focused delivery.',
      },
      {
        id: 'other-feature-2-3',
        icon: <CloudIcon color='success' />,
        header: 'Cross-platform compatibility',
        text: 'Seamless performance on any OS.',
      },
      {
        id: 'other-feature-2-4',
        icon: <HeadsetMicIcon color='success' />,
        header: 'QA and testing',
        text: 'Delivers glitch-free software.',
      },
      {
        id: 'other-feature-2-5',
        icon: <IntegrationInstructionsIcon color='success' />,
        header: 'Continuous integration & delivery',
        text: 'Streamlined updates and deployments.',
      },
      {
        id: 'other-feature-2-6',
        icon: <SecurityIcon color='success' />,
        header: 'Scalability',
        text: 'Ensures your software grows with your business.',
      },
      {
        id: 'other-feature-2-7',
        icon: <EcoIcon color='success' />,
        header: 'Documentation',
        text: 'Detailed guides for effortless use.',
      },
      {
        id: 'other-feature-2-8',
        icon: <HeadsetMicIcon color='success' />,
        header: 'Post-launch support',
        text: 'Handles any post-deployment challenges.',
      },
    ],
    buttonText: 'Contact sales',
  },
]
const PricingSection = () => {
  const theme = useTheme()
  const [selectedPlan, setSelectedPlan] = useState(0)

  const showPricingPlans = false

  const handlePlanChange = (
    _event: React.MouseEvent<HTMLElement>,
    newPlan: number | null
  ) => {
    if (newPlan !== null) {
      setSelectedPlan(newPlan)
    }
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.common.black,
          color: theme.palette.primary.main,
          padding: '6rem 4rem',
          textAlign: 'center',
        }}
      >
        <Typography variant='h3' gutterBottom>
          Simple pricing for everyone
        </Typography>
        <Typography variant='h5' gutterBottom>
          Choose your plan, what suits you best?
        </Typography>
        <Grid
          container
          spacing={6}
          justifyContent='center'
          sx={{ marginTop: '1rem' }}
        >
          <Grid item xs={12} sm={12} md={4}>
            <Card
              sx={{
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: '16px',
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Box
                sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant='h5' gutterBottom>
                    Web Hosting plans
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    Choose a plan according to your needs.
                  </Typography>
                  <Typography
                    variant='h6'
                    color='text.secondary'
                    sx={{ marginTop: '1.5rem' }}
                  >
                    Pricing available on request
                  </Typography>
                  {showPricingPlans && (
                    <Box display={'flex'} justifyContent={'center'} my={2}>
                      <ToggleButtonGroup
                        value={selectedPlan}
                        exclusive
                        onChange={handlePlanChange}
                        sx={{ marginBottom: '1.5rem', width: '100%' }}
                      >
                        {webHostingPlans.map((plan) => (
                          <ToggleButton
                            key={plan.id}
                            value={plan.id}
                            sx={{
                              color: theme.palette.primary.main,
                              flex: 1,
                              borderRadius: '25px',
                              p: 1.5,
                            }}
                          >
                            <Typography variant='body1' letterSpacing={2}>
                              {plan.price}
                            </Typography>
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                      <Typography
                        variant='h6'
                        mt={1.2}
                        ml={2}
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        {' '}
                        / month{' '}
                      </Typography>
                    </Box>
                  )}
                  <Box sx={{ textAlign: 'left', marginTop: '1.5rem' }}>
                    {webHostingPlans[selectedPlan].features.map((feature) => (
                      <Fade delay={100} key={feature.id}>
                        <Box display={'flex'}>
                          <Box mt={1}>{feature.icon}</Box>
                          <Box sx={{ alignItems: 'center', mb: 1 }}>
                            <Typography
                              variant='subtitle1'
                              sx={{ marginLeft: '0.5rem' }}
                              fontWeight={500}
                              letterSpacing={1.1}
                            >
                              {feature.header}
                            </Typography>
                            <Typography
                              variant='body2'
                              sx={{
                                marginLeft: '0.5rem',
                                color: 'lightgray',
                              }}
                            >
                              {feature.text}
                            </Typography>
                          </Box>
                        </Box>
                      </Fade>
                    ))}
                  </Box>
                </CardContent>
                <Button
                  variant='contained'
                  sx={{
                    marginTop: '2rem',
                    padding: '0.7rem 2.5rem',
                    fontSize: '1rem',
                    alignSelf: 'center',
                    width: '80%',
                  }}
                  href='mailto:contact@geniusaisolutions.com'
                >
                  Contact Sales
                </Button>
              </Box>
            </Card>
          </Grid>
          {otherPlans.map((plan) => (
            <Grid item xs={12} sm={12} md={4} key={plan.id}>
              <Fade delay={100}>
                <Card
                  sx={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: '16px',
                    padding: '1.2rem 0rem',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography variant='h5' gutterBottom>
                        {plan.title}
                      </Typography>
                      <Typography variant='body1' gutterBottom>
                        Choose a plan according to your needs.
                      </Typography>
                      {plan.price.map((price) => (
                        <Typography
                          key={price}
                          variant='h6'
                          color='text.secondary'
                          sx={{ marginTop: '1.5rem' }}
                        >
                          {price}
                        </Typography>
                      ))}
                      <Box sx={{ textAlign: 'left', marginTop: '2.6rem' }}>
                        {plan.features.map((feature) => (
                          <Box display={'flex'} key={feature.id}>
                            <Box mt={1}>{feature.icon}</Box>
                            <Box sx={{ alignItems: 'center', mb: 1 }}>
                              <Typography
                                variant='subtitle1'
                                sx={{ marginLeft: '0.5rem' }}
                                fontWeight={500}
                                letterSpacing={1.1}
                              >
                                {feature.header}
                              </Typography>
                              <Typography
                                variant='body2'
                                sx={{
                                  marginLeft: '0.5rem',
                                  color: 'lightgray',
                                }}
                              >
                                {feature.text}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </CardContent>
                    <Button
                      variant='contained'
                      sx={{
                        marginTop: '2rem',
                        padding: '0.7rem 2.5rem',
                        fontSize: '1rem',
                        alignSelf: 'center',
                        width: '80%',
                      }}
                      href='mailto:contact@geniusaisolutions.com'
                    >
                      {plan.buttonText}
                    </Button>
                  </Box>
                </Card>
              </Fade>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  )
}

export default PricingSection
