import GeniusSEO from '../../molecules/GeniusSEO'

const HomePageHelmet = () => {
  const pageTitle = 'Genius AI Solutions | Software Innovation for Business' // MAX LENGTH IS 60 CHARACTERS DO NOT EXCEED
  const pageDescription =
    'Boost your business with Genius AI. Leverage AI/ML and software development to automate tasks, enhance processes, and maximize ROI.' // MAX LENGTH IS 160 CHARACTERS DO NOT EXCEED
  const url = 'https://geniusaisolutions.com/'
  const favicon = url + 'favicon.svg'
  const siteName = 'Genius AI'
  const logoUrl = url + 'images/logo_white.png'
  const keywords = [
    'geniusai',
    'ai genius',
    'genius artificial intelligence',
    'genius site',
    'site genius',
    'genius',
    'genius AI',
    'genus ai',
    'genius.ai',
    'AI Solutions for Business',
    'AI Development Services',
    'Hire AI Developers',
    'Custom AI Software',
    'Machine Learning for Business',
    'AI Process Automation',
    'AI Consulting for Businesses',
    'AI and Machine Learning Experts',
    'AI-Powered Business Applications',
    'Business Process Automation Services',
    'Web and AI Development Services',
    'AI and ML Development Company',
    'Custom Software for Businesses',
    'AI Integration Services',
    'AI-Powered Innovation',
    'AI Technology Consulting',
    'AI-Driven Solutions',
    'Genius AI Software Development',
    'AI Optimization for ROI',
    'Software and AI Development Experts',
    'Cutting-Edge AI Solutions',
    'Business AI Applications',
    'AI Automation for Businesses',
  ]

  return (
    <GeniusSEO
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      url={url}
      favicon={favicon}
      siteName={siteName}
      logoUrl={logoUrl}
      keywords={keywords}
    />
  )
}

export default HomePageHelmet
