import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App.tsx'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme.ts'
import { CssBaseline } from '@mui/material'
import GlobalStyles from './assets/styles/global.tsx'
import { HelmetProvider } from 'react-helmet-async'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://c9ebda42ce8733e56c02dd59efc78c82@o4508390429294592.ingest.us.sentry.io/4508390443319296',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

createRoot(document.getElementById('root')!).render(
  <HelmetProvider>
    <StrictMode>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <CssBaseline />
        <App />
      </ThemeProvider>
    </StrictMode>
  </HelmetProvider>
)
