import React, { useState } from 'react'
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Grid,
  Link,
  useMediaQuery,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { common } from '@mui/material/colors'
import GeniusImage from '../../atoms/GeniusImage'
import { EmailOutlined, LinkedIn } from '@mui/icons-material'
import { Breakpoint } from '../../atoms/Breakpoint'
import { Fade, Slide } from 'react-awesome-reveal'

const faqData = [
  {
    category: 'Solutions & Services',
    questions: [
      {
        question:
          'How can Genius AI Solutions help automate my business tasks?',
        answer:
          'Our AI-driven solutions can automate repetitive tasks like data entry, schedule management, and predictive analytics. We help reduce manual effort, freeing up your team to focus on strategic, high-impact work.',
      },
      {
        question: 'Can you work with our existing software and legacy systems?',
        answer:
          'Yes, we specialize in modernizing legacy codebases, enhancing functionality, and integrating new technologies to ensure compatibility with today’s standards, including headless setups and API-driven architectures.',
      },
      {
        question:
          'What types of custom software does Genius AI Solutions develop?',
        answer:
          'We create tailored software solutions for a variety of industries, covering everything from web applications and data management systems to AI-driven platforms and automated workflows, all customized to meet your specific business needs.',
      },
      {
        question:
          'How does your AI improve business insights and decision-making?',
        answer:
          'Our AI-powered analytics provide actionable insights, using advanced data processing to help you understand trends, forecast outcomes, and make data-driven decisions that foster growth and efficiency.',
      },
      {
        question: 'What industries do you work with?',
        answer:
          'We work with clients across diverse industries, including retail, finance, healthcare, education, logistics, and more, providing tailored solutions for unique business challenges.',
      },
    ],
  },
  {
    category: 'Integration & Compatibility',
    questions: [
      {
        question:
          'Will your solutions integrate with our existing platforms and software?',
        answer:
          'Our solutions are designed to integrate smoothly with most existing platforms, utilizing APIs and custom integrations to ensure seamless connectivity and minimal disruption to your operations.',
      },
      {
        question:
          'Do you offer headless solutions for eCommerce and content management?',
        answer:
          'Yes, we specialize in headless solutions, allowing your front-end to remain flexible while relying on a powerful backend. This approach improves performance, scalability, and allows for multi-platform adaptability.',
      },
      {
        question:
          'How do you handle integrations with third-party applications?',
        answer:
          'We design custom integrations for popular third-party tools and services to ensure compatibility and a streamlined user experience, whether it’s CRM software, payment gateways, or analytics platforms.',
      },
      {
        question: 'What steps are involved in ensuring seamless integration?',
        answer:
          'We conduct a thorough system analysis, create a tailored integration plan, perform rigorous testing, and provide ongoing support to ensure a smooth implementation.',
      },
      {
        question: 'Can you migrate data during the integration process?',
        answer:
          'Yes, we handle secure data migration as part of the integration process, ensuring your data is transferred accurately without any loss or downtime.',
      },
    ],
  },
  {
    category: 'AI & Data Security',
    questions: [
      {
        question:
          'How does Genius AI Solutions ensure data security with AI integration?',
        answer:
          'We use advanced AI algorithms to monitor, detect, and respond to potential threats, automating security processes and safeguarding sensitive information to ensure compliance and protect your business.',
      },
      {
        question: 'What kind of predictive analytics do you offer?',
        answer:
          'Our AI models analyze your historical data to identify trends and predict future outcomes, such as sales forecasts, resource needs, and operational demands, helping you stay ahead in a dynamic market.',
      },
      {
        question: 'How do you handle data privacy and compliance?',
        answer:
          'We prioritize data privacy and compliance with regulations such as GDPR and CCPA, implementing robust measures to ensure secure data handling and storage.',
      },
      {
        question: 'What AI tools do you use for cybersecurity?',
        answer:
          'We use AI tools for threat detection, anomaly analysis, and real-time incident response, providing comprehensive protection against cyber threats.',
      },
      {
        question:
          'Can your AI solutions detect and prevent fraudulent activity?',
        answer:
          'Yes, our AI systems are equipped with advanced fraud detection algorithms that monitor transactions and behaviors to identify and prevent fraudulent activities in real-time.',
      },
    ],
  },
  {
    category: 'Payment & Support',
    questions: [
      {
        question: 'What payment options are available for your services?',
        answer:
          'We accept various payment methods, including credit card, PayPal, and bank transfers. Our team will work with you to ensure a smooth transaction process.',
      },
      {
        question: 'Do you provide ongoing support for your solutions?',
        answer:
          'Yes, we offer comprehensive support options including 24/7 technical support, maintenance, and updates to ensure your software runs smoothly and stays up-to-date.',
      },
      {
        question: 'Are payment plans or financing options available?',
        answer:
          'We offer flexible payment plans to accommodate different budget requirements. Contact our support team to discuss a plan that works for you.',
      },
      {
        question: 'What is your process for resolving technical issues?',
        answer:
          'We have a dedicated support team that responds promptly to any technical issues, providing troubleshooting, regular updates, and comprehensive resolutions.',
      },
      {
        question: 'How do you handle billing disputes or adjustments?',
        answer:
          'Our billing team works closely with clients to review and resolve any discrepancies, ensuring clear communication and satisfaction with the outcome.',
      },
    ],
  },
  {
    category: 'Getting Started',
    questions: [
      {
        question: 'How do I start working with Genius AI Solutions?',
        answer:
          'Getting started is simple. Reach out to us for a consultation, and we’ll discuss your project requirements, goals, and timelines to create a customized solution tailored to your business needs.',
      },
      {
        question: 'What is the typical timeline for project completion?',
        answer:
          'Project timelines vary based on complexity and scope. We’ll provide a detailed timeline during the planning phase and keep you updated throughout the project to ensure timely delivery.',
      },
      {
        question: 'What information do I need to provide for a consultation?',
        answer:
          'For a consultation, we’ll need basic details about your business, your current challenges, and the goals you want to achieve with our solutions.',
      },
      {
        question: 'Do you offer free consultations?',
        answer:
          'Yes, we provide free initial consultations to discuss your requirements, explore potential solutions, and determine the best approach for your business.',
      },
      {
        question: 'What happens after the initial consultation?',
        answer:
          'After the consultation, we’ll create a detailed proposal outlining the scope of work, timelines, and costs, giving you a clear understanding of the project before moving forward.',
      },
    ],
  },
]

const FAQSection = () => {
  const theme = useTheme()
  const [selectedTab, setSelectedTab] = useState(0)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          color: common.black,
          padding: { xs: '2rem 1rem', md: '4rem 2rem' },
          textAlign: 'left',
        }}
      >
        <Box sx={{ maxWidth: 'lg', margin: '0 auto' }}>
          <Fade>
            <Typography
              variant='h6'
              sx={{
                color: 'accent.main',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                letterSpacing: 2.5,
                textAlign: 'center',
                mb: 3,
              }}
            >
              FREQUENTLY ASKED QUESTIONS
            </Typography>
          </Fade>
          <Fade delay={120}>
            <Typography
              textAlign={'center'}
              variant='h3'
              fontWeight={'bolder'}
              gutterBottom
            >
              FAQ
            </Typography>
          </Fade>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            centered={!isMobile}
            textColor='inherit'
            indicatorColor='primary'
            variant={isMobile ? 'scrollable' : 'standard'} // Enable scrolling for smaller screens
            scrollButtons={isMobile ? 'auto' : false} // Show scroll buttons on mobile
            allowScrollButtonsMobile // Ensures buttons work on mobile devices
            sx={{ marginBottom: '2rem', maxWidth: '100%' }}
          >
            {faqData.map((category, index) => (
              <Fade key={index}>
                <Tab label={category.category} />
              </Fade>
            ))}
          </Tabs>
          <Box>
            {faqData[selectedTab].questions.map((item, index) => (
              <Slide key={index} direction='up'>
                <Accordion
                  sx={{
                    marginBottom: '1rem',
                    backgroundColor: 'common.white',
                    color: 'common.black',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
                    sx={{ minHeight: '5rem' }}
                  >
                    <Typography
                      fontWeight={'bold'}
                      color={'common.black'}
                      variant='h6'
                    >
                      {item.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      sx={{ opacity: '70%' }}
                      color={'common.black'}
                      variant='body1'
                    >
                      {item.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Slide>
            ))}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          color: common.black,
          textAlign: 'center',
          py: 7,
        }}
      >
        <Fade delay={120}>
          <GeniusImage
            src='/images/GeniusAITransparentMap.png'
            alt='Genius AI Tech Map'
            style={{ maxWidth: '100%', height: 'auto', maxHeight: '370px' }}
          />
        </Fade>
      </Box>

      <Box
        display='flex'
        sx={{
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid
          container
          spacing={1}
          justifyContent='center'
          sx={{ maxWidth: '1200px' }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Slide direction='left'>
              <Link
                href='mailto:contact@geniusaisolutions.com'
                underline='none'
                color='inherit'
                aria-label='Send an email to Genius AI Solutions support'
              >
                <Box
                  sx={{
                    borderRadius: 2,
                    border: '2px solid lightgray',
                    p: 3,
                    mb: 5,
                    width: '300px',
                    height: '200px',
                    '&:hover': {
                      borderColor: '#84F414',
                      boxShadow: '0px 4px 15px rgba(132, 244, 20, 0.3)',
                      transition: 'all 0.3s ease',
                    },
                  }}
                >
                  <EmailOutlined
                    sx={{
                      fontSize: '3rem',
                      color: '#84F414',
                      marginBottom: '0.5rem',
                    }}
                  />
                  <Typography variant='h2' mb={2}>
                    Email
                  </Typography>
                  <Typography variant='body1'>
                    contact@geniusaisolutions.com
                  </Typography>
                </Box>
              </Link>
            </Slide>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Fade delay={100}>
              <Box
                sx={{
                  borderRadius: 2,
                  border: '2px solid lightgray',
                  p: 2,
                  mb: 5,
                  minWidth: '300px',
                  minHeight: '200px',

                  '&:hover': {
                    borderColor: '#84F414',
                    boxShadow: '0px 4px 15px rgba(132, 244, 20, 0.3)',
                    transition: 'all 0.3s ease',
                  },
                }}
              >
                <EmailOutlined
                  sx={{
                    fontSize: '3rem',
                    color: '#84F414',
                    marginBottom: '0.5rem',
                  }}
                />
                <Typography variant='h2' mb={1}>
                  Address
                </Typography>
                <Typography variant='body1'>
                  431B 41st Avenue NE
                  <Breakpoint />
                  Calgary, AB T2E 2N4 PMB 54
                </Typography>
              </Box>
            </Fade>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Slide direction='right'>
              <Link
                href='https://www.linkedin.com/company/geniusaisolutions/'
                target='_blank'
                rel='noreferrer'
                underline='none'
                color='inherit'
                aria-label='Connect with Genius AI Solutions on LinkedIn'
              >
                <Box
                  sx={{
                    borderRadius: 2,
                    border: '2px solid lightgray',
                    p: 3,
                    mb: 5,
                    width: '300px',
                    height: '200px',
                    '&:hover': {
                      borderColor: '#84F414',
                      boxShadow: '0px 4px 15px rgba(132, 244, 20, 0.3)',
                      transition: 'all 0.3s ease',
                    },
                  }}
                >
                  <LinkedIn
                    sx={{
                      fontSize: '3rem',
                      color: '#84F414',
                      marginBottom: '0.5rem',
                    }}
                  />
                  <Typography variant='h2' mb={2}>
                    LinkedIn
                  </Typography>
                  <Typography variant='body1'>Connect with us!</Typography>
                </Box>
              </Link>
            </Slide>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default FAQSection
