/** @jsxImportSource @emotion/react */
import { Global, css } from '@emotion/react'

const GlobalStyles = () => (
  <Global
    styles={css`
      @font-face {
        font-family: 'Aeonic';
        src:
          url('/fonts/aeonic.woff2') format('woff2'),
          url('/fonts/aeonic.woff') format('woff');
        font-weight: normal;
        font-style: normal;
      }

      /* Custom scrollbar for WebKit browsers (Chrome, Safari, Edge) */
      *::-webkit-scrollbar {
        width: 11px;
      }

      *::-webkit-scrollbar-track {
        background: #0c0c0c; /* Background color for the track */
      }

      *::-webkit-scrollbar-thumb {
        background-color: #84f414; /* Color of the scroll thumb */
        border-radius: 10px; /* Roundness of the scroll thumb */
        border: 2px solid #f1f1f1; /* Padding around the thumb */
      }

      *::-webkit-scrollbar-thumb:hover {
        background-color: #ffffff; /* Color of the scroll thumb when hovered */
      }

      /* Custom scrollbar for Firefox */
      * {
        scrollbar-width: thin;
        scrollbar-color: #84f414 #0c0c0c;
      }

      body {
        font-family: 'Aeonic', Arial, sans-serif;
      }
    `}
  />
)

export default GlobalStyles
