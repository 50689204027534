import { Box } from '@mui/material'
import { Fade } from 'react-awesome-reveal'
import { styles } from './HomePage.styles'
import HeroSection from '../../organisms/Homepage/HeroSection'
import StatisticSection from '../../organisms/Homepage/StatisticSection'
import { homepageFeatures } from '../../../config/constants'
import FeaturesGrid from '../../organisms/Homepage/FeaturesGrid'
import PricingSection from '../../organisms/Homepage/PricingSection'
import FAQSection from '../../organisms/Homepage/FAQSection'
import CustomHeader from '../../organisms/Header/CustomHeader'
import CustomFooter from '../../organisms/Footer/CustomFooter'
import OurSpecialitiesSection from '../../organisms/Homepage/SpecialitySection'
import HomePageHelmet from './HomePageHelmet'

const HomePage = () => {
  return (
    <>
      <HomePageHelmet />
      <CustomHeader />
      <Box sx={styles.mainContainer}>
        <Box component='main' sx={styles.mainContent}>
          <Fade>
            <HeroSection />
          </Fade>
          <Fade>
            <StatisticSection />
          </Fade>
          <Fade>
            <OurSpecialitiesSection />
          </Fade>
          {/* <VideoShowcase /> */}
          <Fade>
            <FeaturesGrid
              sectionTitle='What Sets Us Apart'
              sectionSubtitle='AI Introduction'
              features={homepageFeatures}
            />
          </Fade>
          {/* <ReviewSection /> */}
          <Fade>
            <PricingSection />
          </Fade>
          <FAQSection />
        </Box>
      </Box>
      <CustomFooter />
    </>
  )
}

export default HomePage
