import { Box, Grid, Typography } from '@mui/material'
import theme from '../../../theme'
import AccentTitle from '../../atoms/AccentTitle'
import { Fade } from 'react-awesome-reveal'
import { FeaturesGridProps } from '../../../types/homepage/HomePageTypes'

const FeaturesGrid = ({
  features,
  sectionTitle,
  sectionSubtitle,
}: FeaturesGridProps) => {
  return (
    <Box
      sx={{
        py: 4,
        textAlign: 'center',
        backgroundColor: theme.palette.common.white,
        minHeight: '700px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AccentTitle>
        {' '}
        {sectionSubtitle?.toUpperCase() || 'Introduction'}
      </AccentTitle>
      <Typography
        variant='h2'
        fontWeight='bold'
        color={theme.palette.common.black}
      >
        {sectionTitle}
      </Typography>
      <Grid container spacing={1} justifyContent='center'>
        {features.map(({ icon, title, description }, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Fade delay={100}>
              <Box sx={{ textAlign: 'left', padding: '10%' }}>
                <Box sx={{ mb: 1 }}>{icon}</Box>
                <Typography
                  variant='h5'
                  fontWeight='bold'
                  color={theme.palette.common.black}
                  mb={2}
                >
                  {title}
                </Typography>
                <Typography variant='h6' sx={{ color: '#555' }}>
                  {description}
                </Typography>
              </Box>
            </Fade>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default FeaturesGrid
