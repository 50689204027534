import {
  Box,
  Typography,
  Paper,
  Container,
  Link,
  useMediaQuery,
} from '@mui/material'
import theme from '../../../theme'
import { Slide } from 'react-awesome-reveal'
import { SpecialityCardProps } from '../../../types/homepage/HomePageTypes'

const SpecialityCard = ({
  title,
  description,
  icon,
  height,
  backgroundImage,
  backgroundPosition,
  justifyContent,
  href,
}: SpecialityCardProps) => (
  <Link href={href} underline='none'>
    <Paper
      sx={{
        p: 3,
        backgroundImage: `url(${backgroundImage})`,
        backgroundImageSize: 'cover',
        backgroundPosition: backgroundPosition || 'center',
        backgroundRepeat: 'no-repeat',
        color: '#ffffff',
        borderRadius: 2,
        minHeight: height,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: justifyContent || 'flex-start',
        gap: 1,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
        border: '2px solid transparent',
        '&:hover': {
          border: '2px solid #84F414',
        },
      }}
    >
      <Box sx={{ fontSize: 48 }}>{icon}</Box>
      <Typography variant='h6'>{title}</Typography>
      <Typography variant='body2' sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
        {description}
      </Typography>
    </Paper>
  </Link>
)

const OurSpecialitiesSection = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Container sx={{ p: 4, my: 10 }}>
      <Box maxWidth={'lg'} sx={{ margin: '0 auto' }}>
        {/* Service Section */}
        <Typography variant='h3' align='center' sx={{ mt: 3, mb: 2 }}>
          Our Specialities
        </Typography>
        <Typography variant='body1' align='center' sx={{ mb: 5 }}>
          We specialize in a wide range of services that leverage artificial
          intelligence and machine learning to deliver impactful results.
        </Typography>

        {/* Responsive CSS Grid Layout for Service Cards */}
        <Box
          sx={{
            display: 'grid',
            gap: 3,
            gridTemplateColumns: {
              xs: '1fr', // Single column layout on extra-small screens
              sm: '1fr 1fr', // Two-column layout on small screens
              md: 'repeat(3, 1fr)', // Three-column layout on medium and larger screens
            },
            gridTemplateAreas: {
              xs: `
                                "process"
                                "competitive"
                                "data"
                                "content"
                                "it"
                                "customer"
                            `,
              sm: `
                                "process process"
                                "competitive data"
                                "content it"
                                "customer customer"
                            `,
              md: `
                                "process process competitive"
                                "data content it"
                                "data customer customer"
                            `,
            },
          }}
        >
          <Box sx={{ gridArea: 'process' }}>
            <Slide direction='left'>
              <SpecialityCard
                title='Process Automation'
                description='Process automation streamlines workflows, reducing manual tasks.'
                height={240}
                backgroundImage='/images/backgrounds/process_automation.svg'
                href='/services/process-automation'
              />
            </Slide>
          </Box>
          <Box sx={{ gridArea: 'competitive' }}>
            <Slide direction='right'>
              <SpecialityCard
                title='Competitive Analytics'
                description='Analyze data with AI/ML to gain insights into your competitors like never before.'
                backgroundPosition={isTablet ? 'center' : 'center 200%'}
                height={isMobile ? 240 : isTablet ? 502 : 240}
                backgroundImage='/images/backgrounds/competitive_analytics.svg'
                // href="/services/competitive-analytics"
                href='#'
              />
            </Slide>
          </Box>
          <Box sx={{ gridArea: 'data' }}>
            <Slide direction='left'>
              <SpecialityCard
                title='Data Analytics'
                description='Your data holds the key to unlocking new opportunities and growth.'
                height={isMobile ? 240 : 503}
                backgroundImage='/images/backgrounds/data_analytics.svg'
                justifyContent='flex-end'
                // href="/services/data-analytics"
                href='#'
              />
            </Slide>
          </Box>
          <Box sx={{ gridArea: 'content' }}>
            <Slide direction='down'>
              <SpecialityCard
                title='Content Curation'
                description='AI-driven content curation helps businesses deliver personalized experiences almost instantly.'
                height={240}
                backgroundImage='/images/backgrounds/content_curation.svg'
                backgroundPosition='center 95%'
                // href="/services/content-curation"
                href='#'
              />
            </Slide>
          </Box>
          <Box sx={{ gridArea: 'it' }}>
            <Slide direction='right'>
              <SpecialityCard
                title='IT Security'
                description='Protect your business from cyber threats with AI-powered security solutions.'
                height={240}
                backgroundImage='/images/backgrounds/it_security.svg'
                // href="/services/it-security"
                href='#'
              />
            </Slide>
          </Box>
          <Box sx={{ gridArea: 'customer' }}>
            <Slide direction='right'>
              <SpecialityCard
                title='Customer Service'
                description='Automate customer service to provide more efficient support.'
                backgroundPosition='125% 20%'
                height={240}
                backgroundImage='/images/backgrounds/customer_service.svg'
                // href="/services/customer-service"
                href='/'
              />
            </Slide>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default OurSpecialitiesSection
