import GeniusSEO from '../../molecules/GeniusSEO'

const NotFoundTemplateHelmet = () => {
  const pageTitle = 'Page Not Found | Genius AI Solutions' // MAX LENGTH IS 60 CHARACTERS DO NOT EXCEED
  const pageDescription =
    'Oops! The page you’re looking for doesn’t exist or has been moved. Return to the homepage or contact Genius AI Solutions for assistance.' // MAX LENGTH IS 160 CHARACTERS DO NOT EXCEED
  const url = 'https://geniusaisolutions.com/404'
  const favicon = url + 'favicon.svg'
  const siteName = 'Genius AI'
  const logoUrl = url + 'images/logo_white.png'
  const keywords = [
    //don't change keywords below
    'geniusai',
    'ai genius',
    'genius artificial intelligence',
    'genius site',
    'site genius',
    'genius',
    'genius AI',
    'genus ai',
    'genius.ai',
    //dont change keywords above

    // Unique keywords for the Not Found page
    '404 Page Not Found',
    'Page Not Found Genius AI Solutions',
    'Error 404 Genius AI',
    'Missing Page Genius AI',
    'Broken Link Genius AI Solutions',
    'Can’t Find Page Genius AI',
    'Return to Homepage Genius AI',
    'Contact Genius AI for Help',
    'Page Moved or Missing',
    'Genius AI Error Page',
    'AI Solutions Page Not Found',
    'Looking for Genius AI Pages',
    'Lost Page on Genius AI',
    'Home Page Genius AI Solutions',
    'Help with 404 Error',
    'Error Page Assistance Genius AI',
    'Broken Page Genius AI Solutions',
    'Redirect to Genius AI Homepage',
  ]

  return (
    <GeniusSEO
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      url={url}
      favicon={favicon}
      siteName={siteName}
      logoUrl={logoUrl}
      keywords={keywords}
    />
  )
}

export default NotFoundTemplateHelmet
